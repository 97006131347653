import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import React from 'react'
import tw from 'tailwind.macro'
import { ContainerLg } from '../components/container'
import Heading from '../components/heading'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Container = styled.div`
  margin: -1rem;
  ${tw`flex flex-wrap`};
`

const PartnerCardContainer = styled.div`
  ${tw`shadow bg-grey-lightest rounded flex flex-col justify-end overflow-hidden h-full`};
`

const PartnerCardContent = styled.div`
  ${tw`p-4`};
`

const ImageWrapper = styled.div`
  ${tw`flex-grow flex items-center`};
`

const Image = styled.img`
  ${tw`w-full`};
`

const ColumnSpacer = styled.div`
  ${tw`p-4 w-full sm:w-1/2 lg:w-1/3`}
`

const Title = styled.h2`
  ${tw`m-0 my-1 text-lg text-black`}
`

const Address = styled.p`
  ${tw`m-0 text-base text-grey-darkest`};
`

function TestimonialsPage({ data }) {
  const page = data.page.edges[0].node

  const hasImage = p =>
    p.logo && p.logo.image.asset && typeof p.logo.image.asset.url == 'string'

  const partners = data.partners.edges.map(edge => edge.node)
  const partnersWithImages = partners.filter(hasImage)
  const partnersWithoutImages = partners.filter(p => !hasImage(p))

  return (
    <Layout>
      <ContainerLg>
        <SEO title={page.suffix} description={page.description} />
        <Heading level={1}>Referenzen</Heading>
        <Container>
          {partnersWithImages.map((partner, idx) => (
            <ColumnSpacer key={idx}>
              <PartnerCardContainer>
                <ImageWrapper>
                  <Image
                    src={partner.logo.image.asset.url}
                    alt={partner.logo.imageText}
                  />
                </ImageWrapper>
                <PartnerCardContent>
                  <Title level={2}>{partner.title}</Title>
                  <Address>in {partner.address}</Address>
                </PartnerCardContent>
              </PartnerCardContainer>
            </ColumnSpacer>
          ))}

          {partnersWithoutImages.map((partner, idx) => (
            <ColumnSpacer key={idx}>
              <PartnerCardContainer>
                <PartnerCardContent>
                  <Title level={2}>{partner.title}</Title>
                  <Address>in {partner.address}</Address>
                </PartnerCardContent>
              </PartnerCardContainer>
            </ColumnSpacer>
          ))}
        </Container>
      </ContainerLg>
    </Layout>
  )
}

export const query = graphql`
  query TestimonialsPageQuery {
    page: allSanityPage(filter: { path: { eq: "/referenzen" } }) {
      edges {
        node {
          suffix
          description
        }
      }
    }

    partners: allSanityPartner {
      edges {
        node {
          title
          address
          logo {
            imageText
            image {
              asset {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default TestimonialsPage
